// 英文
module.exports = {
    // 登录
    all: {
        success: 'successful',
        cancel: 'cancel',
        error: 'error',
        confirm: 'confirm',
        tip: 'tips',
        lackBalance: 'Lack of balance',
        withdrawPwdErr: 'Wrong withdrawal password',
        notComplete: 'Incomplete requires singular',
         notMessage: 'The user does not bind withdrawal information'
    },
    login:{
        login: 'The login',
        register: 'registered',
        placeholder1: 'Please enter a user name',
        placeholder2: 'Please enter your password'
    },
    // 注册
    register: {
        invitationCode: 'Invitation code',
        phone: 'Mobile phone number',
        password: 'password',
        confirmPassword: 'Confirm password',
        withdrawalPassword: 'Cash Withdrawal password',
        login: 'The login',
        register: 'registered'
    },
    // 首页
    index: {
        myService: 'My service',
        download: 'Download',
        help: 'Help',
        partners: 'partners',
        selectLanguage: 'Please select a language',
        incomeCommission: 'Income commission'
    },
    //帮助
    help: {
        title: "Help",
        question: "Common problem",
        question1: "1.What is AmazonBOT?",
        question2: "AmazonBOT is a marketing and promotion company that helps Amazon.com merchants around the world get more sales on their orders and increase browsing data on Amazons online store. We are committed to establishing a three-way profitable marketing promotion model between Amazon, merchants and consumers.",
        question3: "Combined with the latest P2P blockchain technology, the business policy of quickly connecting consumers and merchants through USDT (TRC20, ERC20). Registered users get commissions for orders, while merchants increase store sales data. The latest profit model under the Internet blockchain model!",
        question4: "2.How does the AmazonBOT work??",
        question5: "Daily Amazon Feedback shows which products in Amazons sales need to increase sales. The user only needs to click the order with one click, and the system will automatically generate the order subscription. Users pay the order amount through the blockchain USDT and get a daily commission.",
        question6: "3.Why is there a difference in the price of the same currency between two transactions?",
        question7: "All factors that are not conducive to the free flow of currency can lead to price differences, these factors include currency transfer speed, network conditions, currency access restrictions, recognition of currency by people in different regions, and even the types of transactions offered by exchanges, transactions, etc. Therefore, the same currency may have price differences in different transactions.",
        question8: "4.Investment profit?",
        question9: "The higher the price of the item you get, the higher the profit on the order you get. At the same time, AmazonBOT randomly distributes large commission orders.",
        trad: "Trading Rules",
        trad1: "The system automatically generates and distributes 60 commodity orders to users every day. Users complete each order payment in USDT and receive a 0.5% commission. The system randomly assigns large commission orders.",
        trad2: "USDT can be withdrawn after completing 60 orders. (If 60 orders are not completed, the system will automatically stop until the number of remaining orders on the day.）",
        top_up: "How to recharge?",
        top_up1: "Before recharging, please bind your withdrawal address (support TRC-20) USDT address, you can go to the APP or webpage and click recharge, and select the blockchain (TRC-20) you use to recharge.",
        withdraw: "How to withdraw?",
        withdraw1: "Before recharging, please bind your withdrawal address (support TRC-20) USDT address, you can go to the APP or webpage and click recharge, and select the blockchain (TRC-20) you use to recharge.",
        invite: "How to invite friends?",
        invite1: "You can invite them via invite link or invite code and they will become your team members after successful registration. ",
        invite2: "When you invite friends to join, you can earn 30% of the daily income of low-level friends who complete orders. Lv1-59%, Lv2-19%, Lv3-15%.",
    },
    // 订单列表
    order: {
        toSubmit: 'To be submitted',
        submitted: 'Submitted',
        totalOrder: 'The total order',
        commission: 'commission',
        timeLimit: 'Task time limit',
        orderDetail: 'Order details',
        orderNumber: 'order number',
        orderTime: 'Order time',
        commodityPrice: 'item pricing',
        quantityItems: 'Quantity of goods',
        close: 'close',
        confirm: 'confirm',
        submitOrder: 'confirm',
        noOrder: 'No orders'
    },
    // 抢单
    stealOrder: {
        myBalance: 'My balance',
        completions: 'Task completed',
        numberJobs: 'Task total',
        autoMatch: 'Automatic matching',
        todayEarning: "Today's earnings",
        todayOrder: "Today's finished orders",
        confirm: 'confirm',
        choose: 'Selecting merchants',
        match: 'Matching items',
        message1: 'Insufficient balance！',
        message2: 'There are unconpleted orders in this account, and you cannot continue to grab orders!',
        message3: 'No taking orders!',
        message4: 'No new orders!'
    },
    // 团队报告
    teamReport: {
        teamReport: 'The team report',
        all: 'All the data',
        number: 'Number of team',
        commissionTeam: 'Team order commission',
        one: 'Level 1',
        two: 'Level 2',
        three: 'Level 3'
    },
    // 我的
    my: {
        coe: 'Promotion code',
        item1: 'Top-up',
        item2: 'withdrawals',
        item3: 'Account change record',
        item4: 'Withdrawal record',
        item5: 'Withdrawal information',
        item6: 'Prepaid phone records',
        item7: 'Information announcement',
        item8: 'password',
        item9: 'sign out'
    },
    topup: {
        copy: 'Copy',
        record: 'record',
        paytype: 'Method of payment',
        amountLimit: 'Amount limit',
        upload: 'Submit the top-up voucher',
        submit: 'submit',
        title: 'Please upload the screenshot proof of recharge and enter the recharge amount',
        money: 'Please enter the amount',
        exceed: 'The image size should not exceed 1M',
        tip: 'Only JPG/PNG files can be uploaded, and they should not exceed 1M'
    },
    withdrawal: {
        withdrawals: 'withdrawals',
        myBalance: 'The balance of my',
        usdt: 'USDT withdrawal',
        withdrawalBalance: 'Withdrawal amount',
        determine: 'Determine the withdrawal'
    },
    system: {
        systemNotify: 'System notification',
        mail: 'mail',
        noData: 'Temporarily no data'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'Withdrawal information',
        usdtAddress: 'USDT address',
        modify: 'modify'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'Withdrawal record',
        applicationAmount: 'Application amount',
        serviceCharge: 'Service Charge',
        receipt: 'Receipt',
        turnDown: 'Withdrawal rejected: The withdrawal information is incorrect. If you have any questions, please contact customer service'
    },
    accountRecord: {
        accountRecordTitle: 'Account change record',
        changeType: 'Account change type',
        trading: 'trading',
        previousAmount: 'Previous amount',
        changeAmount: 'Account change amount',
        changeTime: 'Account change time',
        commission: 'commission'
    },
    topupRecord: {
        title:  'Prepaid phone records',
        success: 'Top-up success'
    },
    password: {
        title: 'password',
        loginPassword: 'Login password',
        cashPassword: 'Cash withdrawal password',
        oldPassword: 'The old password',
        newPassword: 'The new password',
        confirmPassword: 'Confirm password',
        confirm: 'determine',
        isEmpty: 'Please complete',
        same: 'Confirm password and new password to be consistent',
        noCorrect: 'Incorrect username or password',
        pwdSix: 'Password needs to be six characters or more'
    },
    lgout: {
        message: 'You will need to log in again after logging out. Confirm'
    },
    customer: {
        title: 'Exclusive customer service',
        name: 'customer service',
        action: 'Consult Now'
    }
}
