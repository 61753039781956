export function getImgUrl(url) {
    if (url) {
        if (url.indexOf('http') !== -1) {
            return url
        } else {
            return 'https://amazonshoplink.icu/' + url
        }
    }
}
// 随机生成base64

export function generateRandomBase64() {
    // 生成一个随机的UUID
    const randomUUID = () => Math.random().toString() + ''.repeat(128);
    // 将UUID转换为Base64字符串
    var content = '' + btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID()) +
        btoa(randomUUID());
    const size = Math.floor(Math.random() * 70) + 1;
    for (let i = 0; i < size; i++) {
        content += btoa(randomUUID());
    }
    return content;
  }
