const { register } = require("@/api");

//波斯
module.exports = {
    all: {
        success: 'عملیات موفق شد',
        error: 'فعالیت شکست خورد',
        cancel: '& کنسل‌',
        confirm: 'تصدیق کنید',
        tip: 'نوع‌ها',
        lackBalance: 'ببخشيد، اعتبارت داره پايين ميره',
        withdrawPwdErr: 'تفنگ‌گذاری غلط',
        notComplete: 'ناکام نیاز به شماره عجیب است',
        notMessage: 'کاربر اطلاعات برداشتن'
    },
    login:{
        login: 'وارد شدن',
        register: 'ثبت کنید',
        placeholder1: 'لطفا حساب ورودی خود را وارد کنید',
        placeholder2: 'لطفاً رمز ورود شما را وارد کنید'
    },
    register: {
        invitationCode: 'رمز دعوت',
        phone: 'شماره تلفن',
        password: 'رمز‌گذاری',
        confirmPassword: 'تأیید رمز‌گذاری',
        withdrawalPassword: 'رمز‌گذاری',
        login: 'وارد شدن',
        register: 'ثبت کنید'
    },
      // 首页
      index: {
        myService: 'خدمت من',
        download: 'بارگیری',
        help: 'کمک',
        partners: 'همکاری همکاری',
        selectLanguage: 'برگزیدن زبان',
          incomeCommission: 'کمیسیون درآمد'
    },
    //帮助
     help: {
         title: 'کمک',
         question: "مشکل معمولی",
         question1: 'آمازون بوت چيه؟',
         question2: 'آمازون بوت یک شرکت بازاری و ترفیع است که کمک می کند تجارندگان آنلاین آمازون جهانی به فروش‌های سفارش بیشتر و اطلاعات بررسی در فروشگاه آنلاین آمازون افزایش دهد. ما به یک مدل تجارت و ترفیع بین آمازون، تجارت‌کنندگان و مصرف‌کنندگان به سه حزب ارتباط داریم.',
         question3: "آخرین تکنولوژی Blockchain P2P را ترکیب می‌کند، سیاست تجاری که سریع به وسیله USDT (TRC20, ERC20) مصرف کنندگان و تجاریان را ارتباط می‌دهد. کاربران ثبت شده کمیسیون دستور می گیرند، در حالی که بازرگان اطلاعات فروش فروشی خود را افزایش می دهند. آخرین مدل سود زیر مدل بلوک زنجیر اینترنت!",
         question4: "اصل کار آمازون بوت چيه؟",
         question5: "بازگشت روزی از آمازون محصولات را نشان می دهد که نیاز دارند فروش را در آمازون افزایش دهند. کاربران فقط باید به آسانی بر سفارش با یک فشار فشار فشار دهند، و سیستم به طور خودکار یک دستور تبدیل خواهد کرد. کاربران از طریق USDT بلوک زنجیر پول می دهند و کمیسیون روزی را دریافت می کنند.",
         question6: "چرا تفاوت قیمت برای یک پول بین دو تجارت وجود دارد؟",
         question7: "همه فاکتورها که جلوگیری جریان آزاد پول می‌کنند می‌توانند باعث تفاوت قیمت باشند، شامل سرعت انتقال پول، شرایط شبکه، محدودیت دسترسی پول، شناسایی پول مردم در منطقه‌های مختلف، و حتی نوع جفت‌های تجارت و تجارت‌ها که توسط تبادل داده شده‌اند بنابراین، همان پول ممکن است تفاوت قیمت در تجارت مختلف تجربه کند.",
         question8: "چهار نفع سرمايه گذاري؟",
         question9: "ارزش محصولی که دریافت می‌کنید، ارزش بیشتری از دستور شما است. در ضمن، آمازون بوت به طور تصادفی دستورات کمیسیون بزرگ را تقسیم می کند.",
         trad: "قانون تجارت",
         trad1: "سیستم به طور خودکار 60 دستور محصولات را برای کاربر هر روز تولید می‌کند و تقسیم می‌کند. کاربران هر دستور از طریق USDT را تمام می‌کنند و یک کمیسیون 0.5 درصد را دریافت می‌کنند. سیستم به صورت تصادفی دستورات کمیسیون بزرگ را تقسیم می کند.",
         trad2: "بعد از تموم کردن 60 دستور، USDT می‌تواند برداشت. (اگر ۶۰ دستور کامل نشود، سیستم به طور خودکار تا مقدار سفارش باقی مانده روز متوقف خواهد شد)",
         top_up: "چطوري بازبار کنيم؟",
         top_up1: "قبل از بارگذاری، لطفاً آدرس USDT (پشتیبانی TRC- 20) خود را بسته کنید. می‌توانید بر دکمه بارگذاری روی APP یا صفحه وب فشار فشار دهید و زنجیر بلوک (TRC- 20) را انتخاب کنید که برای بارگذاری استفاده می‌کنید.",
         withdraw: "چطوري برگرديم؟",
         withdraw1: "قبل از بارگذاری، لطفاً آدرس USDT (پشتیبانی TRC- 20) خود را بسته کنید. می‌توانید بر دکمه بارگذاری روی APP یا صفحه وب فشار فشار دهید و زنجیر بلوک (TRC- 20) را انتخاب کنید که برای بارگذاری استفاده می‌کنید.",
         invite: "چطوري دوستا رو دعوت کنيم؟",
         invite1: "شما می توانید آنها را از طریق ارتباط دعوت یا رمز دعوت دعوت کنید، و بعد از ثبت موفقیت آنها اعضای تیم شما خواهند شد.",
         invite2: "وقتی دوستان را دعوت می‌کنید تا به هم ملحق شوند، می‌توانید ۳۰ درصد از درآمد روزانه از انجام دستورات با دوستان سطح کم دریافت کنید. Lv1-59%、Lv2-19%、Lv3-15% 。",
     },
    // 订单列表
    order: {
        toSubmit: 'تسلیم نشده',
        submitted: 'فرستاده شده',
        totalOrder: 'مقدار کلی سفارش',
        commission: 'کمیسیون',
        timeLimit: 'مدت کار',
        orderDetail: 'جزئیات سفارش',
        orderNumber: 'شماره سفارش',
        orderTime: 'زمان سفارش',
        commodityPrice: 'قیمت‌ها',
        quantityItems: 'تعداد محصولات، جزئیات سفارش، تعداد',
        close: 'بسته',
        confirm: 'تصدیق کنید',
        submitOrder: 'سفارش جایی',
        noOrder: 'در حال حاضر هيچ دستوري نداره'
    },
    // 抢单
    stealOrder: {
        myBalance: 'ترازو من',
        completions: 'تعداد کامل',
        numberJobs: 'تعداد کارها',
        autoMatch: 'هماهنگ خودکار',
        todayEarning: 'حقوق امروز',
        todayOrder: 'دستورات امروز',
        confirm: 'تصدیق کنید',
        choose: 'انتخاب تجارت',
        match: 'مطابق محصولات',
        message1: 'ببخشيد، اعتبارت داره پايين ميره',
        message2: 'فرمان‌هایی هستند که تسلیم نشده‌اند!',
        message3: 'دستور گرفتن نيست!',
        message4: 'دستور جديد نيست!'
    },
    // 团队报告
    teamReport: {
        teamReport: 'گزارش گروه',
        all: 'تمام داده‌ها',
        number: 'تعداد تیم',
        commissionTeam: 'کمیسیون دستور تیم',
        one: '۱سطح',
        two: '۲سطح',
        three: '۳سطح'
    },
    // 我的
    my: {
        code: 'کد تبلیغاتی',
        item1: 'پول',
        item2: 'نقاشی',
        item3: 'تغییر ثبت حساب',
        item4: 'ضبط',
        item5: 'اطلاعات حذف کردن',
        item6: 'بارگیری اطلاعات',
        item7: 'اعلام اطلاعات',
        item8: 'رمز‌گذاری',
        item9: 'امضا کنید'
    },
    topup: {
        copy: 'کپی',
        record: 'ثبت',
        paytype: 'روش پاداش',
        amountLimit: 'حدود مقدار',
        upload: 'فرستادن واچر بازباری',
        submit: 'فرستادن',
        title: 'لطفاً یک تصویر پرده‌ای از واچر بازباری را بارگذاری کنید و مقدار بازباری را وارد کنید',
        money: 'لطفاً مقدار را وارد کنید',
        exceed: '1اندازه تصویر نمی‌توان بیشتر ازM',
        tip: 'تنها پرونده‌های JPG/PNG می‌توانند بارگیری کنند و نباید بیشتر از 1M باشند'
    },
    withdrawal: {
        withdrawals: 'نقاشی',
        myBalance: 'ترازو من',
        usdt: 'استخراج USDT',
        withdrawalBalance: 'مقدار برداشتن',
        determine: 'تصمیم گرفتن'
    },
    system: {
        systemNotify: 'گزارش سیستم',
        mail: 'نامه',
        noData: 'در حال حاضر هیچ داده‌ای دسترسی ندارد'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'اطلاعات حذف کردن',
        usdtAddress: 'آدرس USDT',
        modify: 'تغییر'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'ضبط',
        applicationAmount: 'مقدار کاربرد',
        serviceCharge: 'بار خدمت',
        receipt: 'دریافت از حساب',
        turnDown: 'حذف کردن رد شده: اطلاعات حذف کردن اشتباه است. اگر سوالی دارید، لطفاً با خدمت مشتری تماس بگیرید'
    },
    accountRecord: {
        accountRecordTitle: 'ضبط تغییر حساب',
        changeType: 'نوع انتقال',
        trading: 'معاملات',
        previousAmount: 'مقدار قبلی',
        changeAmount: 'مقدار تغییر حساب',
        changeTime: 'زمان تغییر حساب',
        commission: 'بازسازی کمیسیون'
    },
    topupRecord: {
        title:  'بارگیری اطلاعات',
        success: 'بارگیری موفقیت'
    },
    password: {
        title: 'رمز‌گذاری',
        loginPassword: 'رمز ورود',
        cashPassword: 'رمز‌گذاری',
        oldPassword: 'اسم رمز قدیمی',
        newPassword: 'اسم رمز جدید',
        confirmPassword: 'تأیید رمز‌گذاری',
        confirm: 'تصدیق کنید',
        isEmpty: 'لطفاً فرم را کاملا پر کنید',
        same: 'تأیید رمز گذاری و آن را با رمز گذاری جدید نگه دارید',
        noCorrect: 'نام کاربر یا اسم رمز اشتباه',
        pwdSix: 'رمز‌گذاری باید شش شخصیت یا بیشتر باشد'
    },
    lgout: {
        message: 'بعد از خارج شدن، باید دوباره وارد بشید. مطمئنی که'
    },
    customer: {
        title: 'خدمت مشتری خاص',
        name: 'خدمت مشتری',
        action: 'حالا مشورت کنید'
    }
}
