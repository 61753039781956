const { register } = require("@/api");

//中文
module.exports = {
    all: {
        success: '操作成功',
        error: '操作失败',
        cancel: '取消',
        confirm: '确认',
        tip: '提示',
        lackBalance: '余额不足',
        withdrawPwdErr: '提现密码错误',
        notComplete: '未完成需要单数',
        notMessage: '用户未绑定提款信息'
    },
    login:{
        login: '登录',
        register: '注册',
        placeholder1: '请输入登录账号',
        placeholder2: '请输入登录密码'
    },
    register: {
        invitationCode: '邀请码',
        phone: '手机号码',
        password: '密码',
        confirmPassword: '确认密码',
        withdrawalPassword: '提现密码',
        login: '登录',
        register: '注册'
    },
      // 首页
      index: {
        myService: '我的服务',
        download: '下载',
        help: '帮助',
        partners: '合作伙伴',
        selectLanguage: '选择语言',
          incomeCommission: '收入佣金'
    },
    //帮助
     help: {
         title: '帮助',
         question: "常见问题",
         question1: '1.AmazonBOT是什么?',
         question2: 'AmazonBOT是一个帮助全球亚马逊网络商户获得更多订单销量，增加亚马逊网络商店中的浏览数据的营销推广公司。我们致力于亚马逊和商户和消费者之间，三方盈利的营销推广模式。',
         question3: "结合最新的P2P区块链技术，通过USDT（TRC20、ERC20）快速连接消费者和商户的经营方针。注册加入的用户获得订单佣金，同时商户增加店铺销售数据。互联网区块链模式下的最新盈利模式！",
         question4: "2.AmazonBOT的工作原理是什么?",
         question5: "每日通过亚马逊反馈显示出在亚马逊中的销量需要提升的商品，用户只需要轻松通过一键点击订单，系统自动生成订单订阅。用户通过区块链USDT支付订单金额，获得每日佣金。",
         question6: "3.为什么两笔交易之间同一货币的价格存在差异?",
         question7: "所有不利于货币自由流动的因素都会造成价格差异，这些因素包括货币转移速度、网络状况、货币准入限制、不同地区人们对货币的认可，甚至交易所提供的交易配对类型、交易等。因此，同一种货币在不同的交易中可能会产生价格差异。",
         question8: "4.投资利润?",
         question9: "你获得的商品价格越高，你获得的订单利润就越高。同时AmazonBOT随机派发大佣金订单。",
         trad: "交易规则",
         trad1: "每天系统自动生成派发给用户60个商品订单，用户通过USDT完成每次订单支付，获得佣金0.5%。系统随机派发大佣金订单。",
         trad2: "完成60次订单后可提款USDT。(未完成60个订单，系统自动停止至当日剩余订单数量）",
         top_up: "如何充值？",
         top_up1: "充值前请先绑定你的提款地址 (支持TRC-20)USDT地址，您可以到APP或者网页点击充值,选择您使用的区块链(TRC-20)来进行充值。",
         withdraw: "如何提现？",
         withdraw1: "充值前请先绑定你的提款地址 (支持TRC-20)USDT地址，您可以到APP或者网页点击充值,选择您使用的区块链(TRC-20)来进行充值。",
         invite: "如何邀请好友？",
         invite1: "您可以通过邀请链接或邀请代码邀请他们，他们将在成功注册后成为您的团队成员。",
         invite2: "当你邀请好友加入时，你可以获得低级别好友每日完成订单的收入的30%。Lv1-59%、Lv2-19%、Lv3-15% 。",
     },
    // 订单列表
    order: {
        toSubmit: '未提交',
        submitted: '已提交',
        totalOrder: '订单总额',
        commission: '佣金',
        timeLimit: '任务时限',
        orderDetail: '订单详情',
        orderNumber: '订单号',
        orderTime: '下单时间',
        commodityPrice: '商品单价',
        quantityItems: '商品数量',
        close: '关闭',
        confirm: '确认',
        submitOrder: '提交订单',
        noOrder: '暂无订单'
    },
    // 抢单
    stealOrder: {
        myBalance: '我的余额',
        completions: '完成数',
        numberJobs: '任务数',
        autoMatch: '自动匹配',
        todayEarning: '今日收益',
        todayOrder: '今日订单',
        confirm: '确认',
        choose: '正在选择商家',
        match: '正在匹配商品',
        message1: '余额不足！',
        message2: '有未提交订单！',
        message3: '禁止抢单!',
        message4: '没有新的订单!'
    },
    // 团队报告
    teamReport: {
        teamReport: '团队报告',
        all: '所有数据',
        number: '团队数量',
        commissionTeam: '团队订单佣金',
        one: '1级',
        two: '2级',
        three: '3级'
    },
    // 我的
    my: {
        code: '推广码',
        item1: '充值',
        item2: '提款',
        item3: '账变记录',
        item4: '提现记录',
        item5: '提款信息',
        item6: '充值记录',
        item7: '信息公告',
        item8: '密码',
        item9: '退出'
    },
    topup: {
        copy: '复制',
        record: '记录',
        paytype: '支付方式',
        amountLimit: '金额限制',

        upload: '提交充值凭证',
        submit: '提交',
        title: '请上传充值截图凭证，并输入充值金额',
        money: '请输入金额',
        exceed: '图片大小不能超过1M',
        tip: '只能上传jpg/png文件，且不超过1M'
    },
    withdrawal: {
        withdrawals: '提款',
        myBalance: '我的余额',
        usdt: 'USDT提现',
        withdrawalBalance: '提现金额',
        determine: '确定提现'
    },
    system: {
        systemNotify: '系统通知',
        mail: '站内信',
        noData: '暂无数据'
    },
    withdrawalInfo: {
        withdrawalsInfo: '提款信息',
        usdtAddress: 'USDT地址',
        modify: '修改'
    },
    withdrawalRecord: {
        withdrawalsRecord: '提现记录',
        applicationAmount: '申请金额',
        serviceCharge: '手续费',
        receipt: '到账',
        turnDown: '提现驳回：提款信息有误，如有疑问请联系客服'
    },
    accountRecord: {
        accountRecordTitle: '账变纪录',
        changeType: '交易类型',
        trading: '交易',
        previousAmount: '之前金额',
        changeAmount: '账变金额',
        changeTime: '账变时间',
        commission: '返佣'
    },
    topupRecord: {
        title:  '充值记录',
        success: '充值成功'
    },
    password: {
        title: '密码',
        loginPassword: '登录密码',
        cashPassword: '提现密码',
        oldPassword: '旧密码',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        confirm: '确认',
        isEmpty: '请填写完整',
        same: '确认密码与新密码需保持一致',
        noCorrect: '用户名或密码错误',
        pwdSix: '密码需要六位及以上'
    },
    lgout: {
        message: '注销后需要再次登录，是否确认'
    },
    customer: {
        title: '专属客户服务',
        name: '客服',
        action: '立即咨询'
    }
}
