const { register } = require("@/api");

//印地语
module.exports = {
    all: {
        success: 'आपरेशन सफल',
        error: 'त्रुटि',
        cancel: 'रद्द करें',
        confirm: 'पुष्टि करें',
        tip: 'टिप्स',
        lackBalance: 'माफ़ करें, आपका क्रेडिट कम चल रहा है',
        withdrawPwdErr: 'कूटशब्द गलत',
        notComplete: 'अपूर्ण संख्या की जरूरत है'
    },
    login:{
        login: 'लॉगिन',
        register: 'रेजिस्टर',
        placeholder1: 'कृपया अपने लागइन खाता भरें',
        placeholder2: 'कृपया अपना लॉगइन पासवर्ड भरें'
    },
    register: {
        invitationCode: 'निमन्त्रण कोड',
        phone: 'फोन संख्या',
        password: 'पासवर्ड',
        confirmPassword: 'पासवर्ड यकीन करें',
        withdrawalPassword: 'कूटशब्द',
        login: 'लॉगिन',
        register: 'रेजिस्टर'
    },
      // 首页
      index: {
        myService: 'मेरी सेवा',
        download: 'डाउनलोड',
        help: 'मदद',
        partners: 'सहयोगी साझेदारी',
        selectLanguage: 'भाषा चुनें',
        incomeCommission: 'कमिशियन प्राप्त करें'
    },
    help: {
        title: 'मदद',
        question: "सामान्य समस्या",
        question1: 'अमाजोन बॉट काय आहे?',
        question2: 'AmazonBOT हा एक मार्केटिंग आणि उत्पन्न कंपनी आहे ज्याने जागतिक अमेजॉन ऑनलाइन व्यापारी अधिक क्रम विक्रेत व्यापार करून अमेजॉन ऑनल आम्ही एका तीन पार्टीच्या लाभवान मार्केटिंग आणि अमेजान, व्यापारी, व्यापारी आणि व्यापारी मध्ये प्रगतिक',
        question3: "या सर्वात नवीन P2P ब्लॉकशैन तंत्रज्ञानाचा संयोजन करण्यासाठी, व्यापारी आणि व्यापारी USDT मार्फत (TRC20, ERC20). रेजिस्ट्रेट केलेल्या व्यापकांना क्रमांश मिळतात, व्यापारी आपल्या दुकान विक्रेत माहिती वाढ इंटरनेट ब्लॉकशैन्सच्या आधारातील सर्वात नवीन लाभ मॉडल!",
        question4: "अमाजोनबोटचा काम करणारा नियम काय आहे?",
        question5: "अमेजान्सपासून रोजीय फीडबॅक दाखविते ज्यांना अमेजान्समध्ये विक्रेत वाढविण्याची गरज आहे. वापरकर्त्यांना फक्त एक क्लिक करून आ वापरकर्त्यांनी ब्लॉक सैन्ज USDT द्वारा क्रमांक पैसे देतात व रोज कॉमिशियन प्राप्त करतात.",
        question6: "दोन ट्रांक्शन्स मध्ये एकाच मुद्रासाठी मुद्रा का फरक आहे?",
        question7: "मुद्राच्या मुक्त प्रवाहाचा अडचण करणाऱ्या सर्व कारणांना मुद्रा स्थानान्तरण गती, नेटवर्क परिस्थिती, मुद्रा प्रवेश संबंधित, विविध प्रदेशात लोकांची मुद् म्हणून, एकच मुद्रा वेगवेगळ्या व्यापारांमध्ये मुद्रा वेगवेगळ्या वेगवेगळ्या वेगवेग",
        question8: "४. शिक्षा लाभ?",
        question9: "तुम्हाला मिळालेल्या उत्पादनाच्या मूल्यावर जास्त अधिक मूल्य, तुमच्या क्रमावर जास्त लाभाच त्याचवेळी, अमाजोनबॉट अनियमित रूपाने मोठ्या कमिशन आज्ञा वितरतो.",
        trad: "ट्रेडिंग नियम",
        trad1: "प्रणाली प्रत्येक दिवशी ६० उत्पादन क्रमांना स्वयंचालित रूपांतरित करतो व वितरतो. वापरकर्ता प्रत्येक क्रम USDT द्वारा प्रत्ये प्रणाली अनियमित रीतीने मोठ्या कमिशन आज्ञा वितरते.",
        trad2: "६० आज्ञा पूर्ण केल्यानंतर, USDT काढू शकतो. (जर ६० क्रम पूर्ण झाले नाही तर प्रणाली दिवसाच्या उरलेल्या क्रमाच्या प्रमाणापर्यंत स्वचालित रुकील)",
        top_up: "पुन्हा चार्ज करायचं कसं?",
        top_up1: "पुन्हा चार्ज करण्यापूर्वी, कृपया तुमचे उत्तरीकृत पत्ता (TRC-20 समर्थन) USDT पत्ता बाइन्ड करा. APP किंवा वेब पानावरील पुन्हा चार्ज बटण वर क्लिक करू शकता व पुन्हा च",
        withdraw: "सोडायचं कसं?",
        withdraw1: "पुन्हा चार्ज करण्यापूर्वी, कृपया तुमचे उत्तरीकृत पत्ता (TRC-20 समर्थन) USDT पत्ता बाइन्ड करा. APP किंवा वेब पानावरील पुन्हा चार्ज बटण वर क्लिक करू शकता व पुन्हा च",
        invite: "मित्रांना आमंत्रित करणं कसं?",
        invite1: "तुम्ही त्यांना आमंत्रण लिंक किंवा आमंत्रण कोड द्वारा आमंत्रण देऊ शकता, आणि ते यशस्वी रिजिस्टेशनीनंतर तुमच्या सम",
        invite2: "जेव्हा तुम्ही मित्रांना जोडण्यासाठी आमंत्रित करता, तेव्हा तुम्हाला कमी स्तर मित्रांना आज्ञा पूर्ण करण्याप Lv1-59%、Lv2-19%、Lv3-15%",
    },
    // 订单列表
    order: {
        toSubmit: 'जमा नहीं किया गया',
        submitted: 'भेजा गया',
        totalOrder: 'कुल क्रम मात्रा',
        commission: 'कमिशन',
        timeLimit: 'कार्य',
        orderDetail: 'क्रम विवरण',
        orderNumber: 'क्रम संख्या',
        orderTime: 'क्रम समय',
        commodityPrice: 'वस्तु मूल्य',
        quantityItems: 'सामग्री की मात्रा',
        close: 'बन्द करें',
        confirm: 'पुष्टि करें',
        submitOrder: 'स्थान क्रम',
        noOrder: 'इस क्षण में कोई आदेश नहीं'
    },
    // 抢单
    stealOrder: {
        myBalance: 'मेरा प्रतिमान',
        completions: 'पूर्ण मात्रा',
        numberJobs: 'कार्यों की संख्या',
        autoMatch: 'स्वचालित मिलान',
        todayEarning: 'आज की कमाई',
        todayOrder: 'आज के आदेश',
        confirm: 'पुष्टि करें',
        choose: 'व्यापारी चुनें',
        match: 'मिलाने वाले उत्पादन',
        message1: 'माफ़ करें, आपका क्रेडिट कम चल रहा है',
        message2: 'कोई आदेश है जो जमा नहीं किया गया',
        message3: 'आदेशों को पकड़ने का रोका',
        message4: 'कोई नया आदेश नहीं है'
    },
    // 团队报告
    teamReport: {
        teamReport: 'टीम रिपोर्ट',
        all: 'सभी डाटा',
        number: 'टीम की संख्या',
        commissionTeam: 'टीम क्रम कमिशन',
        one: 'स्तर 1',
        two: 'स्तर 2',
        three: 'स्तर 3'
    },
    // 我的
    my: {
        code: 'प्रोमोटियन कोड',
        item1: 'फिर चार्ज करें',
        item2: 'रेखाचित्र',
        item3: 'खाता बदलें',
        item4: 'विचड्रावेल रेकॉर्ड',
        item5: 'सूचना',
        item6: 'रेकर्ड फिर चार्ज करें',
        item7: 'जानकारी व्याख्या',
        item8: 'पासवर्ड',
        item9: 'हस्ताक्षर करें'
    },
    topup: {
        copy: 'नकल',
        record: 'रिकार्ड',
        paytype: 'पैमेंट विधि',
        amountLimit: 'मात्रा सीमा',
        upload: 'पुनरार्ज वाचर भेजें',
        submit: 'भेजें',
        title: 'कृपया फिर चार्ज वाचर के स्क्रीनशॉट को अपलोड करें और फिर चार्ज मात्रा भरें',
        money: 'कृपया मात्रा भरें',
        exceed: 'छवि आकार 1M से अधिक नहीं जा सकता',
        tip: 'सिर्फ JPG/PNG फ़ाइल अपलोड कर सकते हैं, और वे 1M से अधिक नहीं करना चाहिए'
    },
    withdrawal: {
        withdrawals: 'रेखाचित्र',
        myBalance: 'मेरा प्रतिमान',
        usdt: 'यूएसडीटी निकालें',
        withdrawalBalance: 'विच्छेदवाल मात्रा',
        determine: 'पुष्टि करें'
    },
    system: {
        systemNotify: 'तंत्र सूचना',
        mail: 'मेल',
        noData: 'यह समय कोई डाटा उपलब्ध नहीं'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'सूचना',
        usdtAddress: 'यूएसडीटी पता',
        modify: 'परिवर्धित करें'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'विचड्रावेल रेकॉर्ड',
        applicationAmount: 'अनुप्रयोग मात्रा',
        serviceCharge: 'सेवा चार्ज',
        receipt: 'खाता द्वारा प्राप्त',
        turnDown: 'हटवाल नाम दिया गया: हटवाल जानकारी गलत है. यदि आपके पास कोई प्रश्न है, कृपया ग्राहक सेवा संपर्क करें'
    },
    accountRecord: {
        accountRecordTitle: 'खाता बदलें',
        changeType: 'ट्रांसेक्शन प्रकार',
        trading: 'लेन-देन',
        previousAmount: 'पिछले मात्रा',
        changeAmount: 'खाता परिवर्तन मात्रा',
        changeTime: 'खाता परिवर्तन समय',
        commission: 'कमिशियन पुनरावृत्ति'
    },
    topupRecord: {
        title:  'रेकर्ड फिर चार्ज करें',
        success: 'पुनरार्ज सफल'
    },
    password: {
        title: 'पासवर्ड',
        loginPassword: 'लॉगइन पासवर्ड',
        cashPassword: 'कूटशब्द',
        oldPassword: 'पुराना पासवर्ड',
        newPassword: 'नया पासवर्ड',
        confirmPassword: 'पासवर्ड यकीन करें',
        confirm: 'पुष्टि करें',
        isEmpty: 'कृपया फॉर्म संपूर्ण रूप में भरें',
        same: 'पासवर्ड पुष्टि करें तथा इसे नया पासवर्ड के साथ सहभागी रखें',
        noCorrect: 'प्रयोगकर्ता नाम वा पासवर्ड गलत',
        pwdSix: 'पासवर्ड सहा अक्षर या अधिक होने की जरूरत है'
    },
    lgout: {
        message: 'क्या आपको लॉग आउट के बाद लॉगइन फिर से पुष्टि करने की जरूरत है'
    },
    customer: {
        title: 'एक्स्क्लुसिव ग्राहक सेवा',
        name: 'ग्राहक सेवा',
        action: 'अब देखें'
    }
}
