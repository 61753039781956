const { register } = require("@/api");

//印尼语
module.exports = {
    all: {
        success: 'Operasi yang berhasil',
        error: 'gagal',
        cancel: 'Membatalkan',
        confirm: 'Menegaskan',
        tip: 'Prompt.',
        lackBalance: 'Maaf, kreditmu kehabisan',
        withdrawPwdErr: 'Sandi tarik salah',
        notComplete: 'Tidak lengkap membutuhkan nomor aneh',
        notMessage: 'Pengguna tidak terikat informasi penarikan'
    },
    login:{
        login: 'Masuk',
        register: 'Register',
        placeholder1: 'Silakan masukkan akun login Anda',
        placeholder2: 'Silakan masukkan kata sandi masuk Anda.'
    },
    register: {
        invitationCode: 'Kode undangan',
        phone: 'Nomor ponsel',
        password: 'Kode rahasia',
        confirmPassword: 'Konfirmasi kata sandi',
        withdrawalPassword: 'Kata sandi Withdra-out',
        login: 'Masuk',
        register: 'Register'
    },
      // 首页
      index: {
        myService: 'Pelayanan saya',
        download: 'Download',
        help: 'Bantuan',
        partners: 'Mitra',
        selectLanguage: 'Pilih bahasa',
        incomeCommission: 'Komisi pendapatan'
    },
    help: {
        title: 'Bantuan',
        question: "Masalah umum",
        question1: 'Apa itu AmazonBOT?',
        question2: 'AmazonBOT adalah perusahaan pemasaran dan promosi yang membantu para pedagang online Amazon global mendapatkan lebih banyak penjualan perintah dan meningkatkan data pelayaran di toko online Amazon. Kami berkomitmen untuk tiga partai model pemasaran dan promosi yang menguntungkan antara Amazon, pedagang, dan konsumen.',
        question3: "Menggabungkan teknologi blockchain P2P terbaru, kebijakan bisnis untuk dengan cepat menghubungkan konsumen dan pedagang melalui USDT (TRC20, ERC20). Pengguna terdaftar menerima komisi pesanan, sementara para pedagang meningkatkan data penjualan toko mereka. Model keuntungan terbaru di bawah model blockchain Internet!",
        question4: "Apa prinsip kerja AmazonBOT?",
        question5: "Balasan harian dari Amazon menunjukkan produk yang perlu untuk meningkatkan jualan di Amazon. Pengguna hanya perlu dengan mudah klik pada perintah dengan satu klik, dan sistem akan secara otomatis menghasilkan subskripsi perintah. Pengguna membayar jumlah perintah melalui blockchain USDT dan menerima komisi harian.",
        question6: "Mengapa ada perbedaan harga untuk uang yang sama antara dua transaksi?",
        question7: "Semua faktor yang menghalangi aliran bebas mata uang dapat menyebabkan perbedaan harga, termasuk kecepatan transfer mata uang, kondisi jaringan, batasan akses mata uang, pengakuan mata uang orang di wilayah berbeda, dan bahkan jenis pasangan perdagangan dan transaksi yang disediakan oleh pertukaran. Oleh karena itu, uang yang sama mungkin mengalami perbedaan harga dalam transaksi yang berbeda.",
        question8: "4. keuntungan investasi?",
        question9: "Semakin tinggi harga produk yang Anda terima, semakin tinggi keuntungan atas pesanan Anda. Sementara itu, AmazonBOT secara acak mendistribusikan perintah komisi besar.",
        trad: "Aturan Penjualan",
        trad1: "Sistem secara otomatis menghasilkan dan mendistribusikan 60 perintah produk kepada pengguna setiap hari. Pengguna menyelesaikan setiap pembayaran perintah melalui USDT dan menerima komisi 0,5%. Sistem secara acak mendistribusikan perintah komisi besar.",
        trad2: "Setelah menyelesaikan 60 perintah, USDT bisa ditarik. (Jika 60 perintah tidak selesai, sistem akan berhenti secara otomatis sampai jumlah perintah yang tersisa dari hari)",
        top_up: "Bagaimana untuk memuat ulang?",
        top_up1: "Sebelum memuat ulang, silakan ikat alamat pengunduran (mendukung TRC-20) USDT Anda. Anda dapat klik pada tombol memuat ulang pada APP atau halaman web, dan pilih blockchain (TRC-20) yang Anda gunakan untuk memuat ulang.",
        withdraw: "Bagaimana untuk mundur?",
        withdraw1: "Sebelum memuat ulang, silakan ikat alamat pengunduran (mendukung TRC-20) USDT Anda. Anda dapat klik pada tombol memuat ulang pada APP atau halaman web, dan pilih blockchain (TRC-20) yang Anda gunakan untuk memuat ulang.",
        invite: "Bagaimana mengundang teman?",
        invite1: "Anda dapat mengundang mereka melalui link undangan atau kode undangan, dan mereka akan menjadi anggota tim Anda setelah mendaftar sukses.",
        invite2: "Ketika Anda mengundang teman untuk bergabung, Anda dapat menerima 30% dari pendapatan sehari-hari dari menyelesaikan perintah dengan teman tingkat rendah. Lv1-59%、Lv2-19%、Lv3-15%.",
    },
    // 订单列表
    order: {
        toSubmit: 'Tidak diserahkan',
        submitted: 'Dikirimkan',
        totalOrder: 'Total Pesanan',
        commission: 'Komisi',
        timeLimit: 'Batas waktu tugas',
        orderDetail: 'Detail pesanan',
        orderNumber: 'Nomor pesanan',
        orderTime: 'Waktu Tempat Pemesanan',
        commodityPrice: 'Harga satuan barang',
        quantityItems: 'Jumlah barang',
        close: 'Tutup',
        confirm: 'Menegaskan',
        submitOrder: 'Kirim pesanan',
        noOrder: 'Tidak ada pesanan yang tersedia'
    },
    // 抢单
    stealOrder: {
        myBalance: 'Saldo saya',
        completions: 'Jumlah penyelesaian',
        numberJobs: 'Jumlah tugas',
        autoMatch: 'Pencocokan otomatis',
        todayEarning: 'Pendapatan hari ini',
        todayOrder: 'Pesanan hari ini',
        confirm: 'Menegaskan',
        choose: 'Memilih pedagang',
        match: 'Barang yang cocok',
        message1: 'Tidak Ada Uang Tidak Ada Kebahagiaan',
        message2: 'Ada pesanan yang belum terkirim',
        message3: 'Dilarang mengambil pesanan.',
        message4: 'Tidak ada pesanan baru.'
    },
    // 团队报告
    teamReport: {
        teamReport: 'Laporan Tim',
        all: 'Semua data',
        number: 'Jumlah tim',
        commissionTeam: 'Komisi pemesanan tim',
        one: 'Tingkat 1',
        two: 'Tingkat 2',
        three: 'Tingkat 3'
    },
    // 我的
    my: {
        code: 'Kode promosi',
        item1: 'Isi Ulang',
        item2: 'Tarik uang dari bank',
        item3: 'Catatan akun',
        item4: 'Catatan penarikan uang tunai',
        item5: 'Informasi Withdra-in',
        item6: 'Catatan top-up',
        item7: 'Pengumuman informasi',
        item8: 'Kode rahasia',
        item9: 'Tinggalkan'
    },
    topup: {
        copy: 'Duplikat',
        record: 'Membuat catatan',
        paytype: 'Metode pembayaran',
        amountLimit: 'Batas jumlah',
        upload: 'Kirim surat jaminan ulang',
        submit: 'Kirim',
        title: 'Silakan mengunggah gambar layar dari jaminan ulang muatan dan masukkan jumlah ulang muatan',
        money: 'Silakan masukkan jumlah',
        exceed: 'Ukuran gambar tidak dapat melebihi 1M',
        tip: 'Hanya berkas JPG/PNG yang dapat diunggah, dan mereka tidak seharusnya melebihi 1M'
    },
    withdrawal: {
        withdrawals: 'Tarik uang dari bank',
        myBalance: 'Saldo saya',
        usdt: 'Penarikan USDT',
        withdrawalBalance: 'Jumlah penarikan tunai',
        determine: 'Tentukan penarikan'
    },
    system: {
        systemNotify: 'Pemberitahuan Sistem',
        mail: 'Surat di stasiun',
        noData: 'Tidak ada data yang tersedia'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'Informasi Withdra-in',
        usdtAddress: 'Alamat USDT',
        modify: 'Ubah'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'Rekaman Withdra-out',
        applicationAmount: 'Jumlah Permintaan',
        serviceCharge: 'Komisi',
        receipt: 'Tiba di akun',
        turnDown: 'Pengunduran ditolak: Informasi pengunduran tidak benar. Jika Anda punya pertanyaan, silakan hubungi layanan pelanggan'
    },
    accountRecord: {
        accountRecordTitle: 'Catatan perubahan akun',
        changeType: 'Jenis transaksi',
        trading: 'Perniagaan',
        previousAmount: 'Jumlah Sebelumnya',
        changeAmount: 'Jumlah perubahan akun',
        changeTime: 'Waktu perubahan akun',
        commission: 'Rabat'
    },
    topupRecord: {
        title:  'Catatan top-up',
        success: 'Berhasil diisi ulang'
    },
    password: {
        title: 'Kode rahasia',
        loginPassword: 'Kata sandi masuk',
        cashPassword: 'Kata sandi penarikan uang tunai',
        oldPassword: 'Kata sandi lama',
        newPassword: 'Kata sandi baru',
        confirmPassword: 'Konfirmasi kata sandi',
        confirm: 'Menegaskan',
        isEmpty: 'Silakan isi.',
        same: 'Konfirmasi bahwa kata sandi harus konsisten dengan kata sandi baru.',
        noCorrect: 'Nama pengguna atau kata sandi salah',
        pwdSix: 'Kata sandi perlu enam karakter atau lebih'
    },
    lgout: {
        message: 'Apakah Anda perlu masuk lagi setelah keluar?'
    },
    customer: {
        title: 'Layanan pelanggan eksklusif',
        name: 'layanan pelanggan',
        action: 'Konsultasi Sekarang'
    }
}
