import Vue from 'vue'
import Router from 'vue-router'
import login from '@/views/login'
import register from '@/views/register'
import home from '@/views/home'
import index from '@/views/index'
import help from '@/views/help'
import order from '@/views/order'
import stealOrder from '@/views/stealOrder'
import teamReport from '@/views/teamReport'
import my from '@/views/my'
import inviteFriends from '@/views/inviteFriends'
import topUp from '@/views/topUp'
import accountRecord from '@/views/accountRecord'
import withdrawMoney from '@/views/withdrawMoney'
import withdrawalRecord from '@/views/withdrawalRecord'
import withdrawalInfo from '@/views/withdrawalInfo'
import rechargeRecord from '@/views/rechargeRecord'
import systemNotification from '@/views/systemNotification'
import password from '@/views/password'
import customerService from '@/views/customerService'
import upData from '@/views/upData'

Vue.use(Router)
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    // 注册
    {
      path: '/register',
      name: 'register',
      component: register
    },
   // 主页面
    {
      path: '/home',
      name: 'home',
      component: home
    },
    // 首页
    {
      path: '/index',
      name: 'index',
      component: index
    },
    // 帮助
    {
      path: '/help',
      name: 'help',
      component: help
    },
    // 订单
    {
      path: '/order',
      name: 'order',
      component: order
    },
    // 抢单
    {
      path: '/stealOrder',
      name: 'stealOrder',
      component: stealOrder
    },
    // 团队报告
    {
      path: '/teamReport',
      name: 'teamReport',
      component: teamReport
    },
    // 我的
    {
      path: '/my',
      name: 'my',
      component: my
    },
    // 邀请好友
    {
      path: '/inviteFriends',
      name: 'inviteFriends',
      component: inviteFriends
    },
    // 充值
    {
      path: '/topUp',
      name: 'topUp',
      component: topUp
    },
    {
      path: '/upData',
      name: 'upData',
      component: upData
    },
    // 账变记录
    {
      path: '/accountRecord',
      name: 'accountRecord',
      component: accountRecord
    },
    // 提款
    {
      path: '/withdrawMoney',
      name: 'withdrawMoney',
      component: withdrawMoney
    },
    // 提现记录
    {
      path: '/withdrawalRecord',
      name: 'withdrawalRecord',
      component: withdrawalRecord
    },
    // 提款信息
    {
      path: '/withdrawalInfo',
      name: 'withdrawalInfo',
      component: withdrawalInfo
    },
    // 充值记录
    {
      path: '/rechargeRecord',
      name: 'rechargeRecord',
      component: rechargeRecord
    },
    // 信息公告
    {
      path: '/systemNotification',
      name: 'systemNotification',
      component: systemNotification
    },
    // 密码
    {
      path: '/password',
      name: 'password',
      component: password
    },
    // 专属客服
    {
      path: '/customerService',
      name: 'customerService',
      component: customerService
    },

  ]
})


