const { register } = require("@/api");

//俄语
module.exports = {
    all: {
        success: 'Успешная операция',
        error: 'неудач',
        cancel: 'Отмена',
        confirm: 'Подтверждение',
        tip: 'Советы',
        lackBalance: 'Недостаточный баланс',
        withdrawPwdErr: 'Ошибка при снятии кодов',
        notComplete: 'Для этого требуется только одно число',
        notMessage: 'Пользователи не связывают информацию об отказе'
    },
    login:{
        login: 'Войти',
        register: 'Зарегистрироваться',
        placeholder1: 'Пожалуйста, введите свой логин.',
        placeholder2: 'Введите пароль для входа.'
    },
    register: {
        invitationCode: 'Код приглашения',
        phone: 'Номер мобильного телефона',
        password: 'Пароль',
        confirmPassword: 'Подтвердить пароль',
        withdrawalPassword: 'Забрать пароль',
        login: 'Войти',
        register: 'Зарегистрироваться'
    },
    // 首页
    index: {
        myService: 'Моя служба',
        download: 'Загрузка',
        help: 'Помощь',
        partners: 'Партнеры',
        selectLanguage: 'Выберите язык',
        incomeCommission: 'Комиссия по доходам'
    },
    help: {
        title: "Помощь",
        question: "Întrebări frecvente",
        question1: "1.Что такое AmazonBOT?",
        question2: "AmazonBOT - это маркетинговая компания, которая помогает мировым торговым площадкам Amazon получать больше заказов и увеличивать количество просмотров в магазинах Amazon. Мы стремимся к тройному выигрышу между Amazon, продавцами и потребителями.",
        question3: "В сочетании с новейшей технологией P2P-блокчейн, быстрым соединением потребителей и продавцов с помощью USDT (TRC20, ERC20). Пользователи, зарегистрированные для участия, получают комиссионные за заказы, а продавцы увеличивают продажи в своих магазинах. Новая прибыльная модель в интернете блокчейна",
        question4: "2.Как работает AmazonBOT?",
        question5: "Каждый день Amazon показывает товары, которые нуждаются в увеличении продаж, и пользователи могут легко подписаться на заказ, нажав одну кнопку. Пользователи оплачивают сумму заказа через USDT (TRC20, ERC20) и получают комиссионные ежедневно.",
        question6: "3.Почему между двумя транзакциями цена одной и той же валюты различается?",
        question7: "Все факторы, которые могут препятствовать свободному движению валюты, могут привести к различиям в цене, включая скорость передачи валюты, состояние сети, ограничения на вход валюты, признание валюты людьми в разных регионах, а также типы пар торговых площадок и т. д. Поэтому одна и та же валюта может иметь различную цену в разных торговых площадках.",
        question8: "4.Инвестиционная прибыль?",
        question9: "Чем выше цена товара, тем выше прибыль от заказа. В то же время AmazonBOT случайным образом распределяет большие комиссионные заказы.",
        trad: "Правила торговли",
        trad1: "Каждый день система автоматически генерирует 60 заказов для пользователей. Пользователи получают комиссию 0,5% за каждый заказ, оплаченный через USDT. Система случайным образом распределяет большие комиссионные заказы.",
        trad2: "После выполнения 60 заказов вы можете вывести USDT. (Если вы не выполнили 60 заказов, система автоматически остановится до оставшегося количества заказов в этот день)",
        top_up: "Как пополнить счет?",
        top_up1: "Перед пополнением счета сначала привяжите свой адрес вывода (поддержка TRC-20) USDT, вы можете нажать кнопку «Пополнить» на приложении или веб-сайте, выбрать блокчейн (TRC-20), который вы используете, чтобы пополнить счет.",
        withdraw: "Как вывести деньги?",
        withdraw1: "Перед пополнением счета сначала привяжите свой адрес вывода (поддержка TRC-20) USDT, вы можете нажать кнопку «Пополнить» на приложении или веб-сайте, выбрать блокчейн (TRC-20), который вы используете, чтобы пополнить счет.",
        invite: "Как пригласить друзей?",
        invite1: "Вы можете пригласить их через ссылку-приглашение или код-приглашение, и они станут членами вашей команды после успешной регистрации.",
        invite2: "Вы можете пригласить их через ссылку-приглашение или код-приглашение, и они станут членами вашей команды после успешной регистрации.",
    },
    // 订单列表
    order: {
        toSubmit: 'Не представлено',
        submitted: 'Представлено',
        totalOrder: 'Общее количество заказов',
        commission: 'Комиссия',
        timeLimit: 'Продолжительность выполнения задания',
        orderDetail: 'Подробная информация о заказе',
        orderNumber: 'Номер заказа',
        orderTime: 'Время для размещения заказа',
        commodityPrice: 'Цена на единицу продукта',
        quantityItems: 'Количество товаров',
        close: 'Закрывать',
        confirm: 'Подтверждение',
        submitOrder: 'Подайте заказ',
        noOrder: 'Пока заказов нет'
    },
    // 抢单
    stealOrder: {
        myBalance: 'Мой баланс',
        completions: 'Количество отделок',
        numberJobs: 'Количество задач',
        autoMatch: 'Автоматическое соответствие',
        todayEarning: 'Сегодняшние доходы',
        todayOrder: 'Заказать сегодня',
        confirm: 'Подтверждение',
        choose: 'Выбор бизнеса',
        match: 'Соответствующий продукт',
        message1: 'Недостаточный баланс！',
        message2: 'Не было представлено никаких заказов！',
        message3: 'Нет Захвата Заказов!',
        message4: 'Новых заказов нет!'
    },
    // 团队报告
    teamReport: {
        teamReport: 'Отчет команды',
        all: 'Все данные',
        number: 'Количество команд',
        commissionTeam: 'Команда заказов комиссии',
        one: 'Уровень 1',
        two: 'Уровень 2',
        three: 'Уровень 3'
    },
    // 我的
    my: {
        code: 'Продвижение Код',
        item1: 'Зарядка',
        item2: 'Изъятия',
        item3: 'Запись изменения учетной записи',
        item4: 'Записи о выходе',
        item5: 'Информация о снятии',
        item6: 'Зарядка Записи',
        item7: 'Информационный бюллетень',
        item8: 'Пароль',
        item9: 'Выход'
    },
    topup: {
        copy: 'Репликация',
        record: 'Записи',
        paytype: 'Способ оплаты',
        amountLimit: 'Ограничение суммы',
        upload: 'Подайте документы на перезарядку',
        submit: 'представ',
        title: 'Пожалуйста, загрузите скриншот загрузки и введите сумму зарядки',
        money: 'Введите сумму.',
        exceed: 'Размер картины не может быть больше 1M',
        tip: 'Можно загружать только файлы jpg / png и не более 1M'
    },
    withdrawal: {
        withdrawals: 'Изъятия',
        myBalance: 'Мой баланс',
        usdt: 'Вывод USDT',
        withdrawalBalance: 'Снятие наличных',
        determine: 'Определение выплат'
    },
    system: {
        systemNotify: 'Системные уведомления',
        mail: 'Внутристанционный Письмо',
        noData: 'Вообще никаких данных нет'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'Информация о снятии',
        usdtAddress: 'Адрес USDT',
        modify: 'Модификация'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'Записи о выходе',
        applicationAmount: 'Сумма заявки',
        serviceCharge: 'Сборы за обработку',
        receipt: 'Заезжать',
        turnDown: 'Отказ в снятии денег: информация о снятии денег неверна, пожалуйста, свяжитесь с клиентом, если у вас есть вопросы'
    },
    accountRecord: {
        accountRecordTitle: 'Запись изменения учетной записи',
        changeType: 'Тип операции',
        trading: 'сделк',
        previousAmount: 'Предыдущая сумма',
        changeAmount: 'Сумма перевода счета',
        changeTime: 'Время перевода.',
        commission: 'В вакуумн пылесос'
    },
    topupRecord: {
        title:  'Запись зарядки',
        success: 'Зарядка завершена.'
    },
    password: {
        title: 'код',
        loginPassword: 'Логин.',
        cashPassword: 'Снять коды.',
        oldPassword: 'Старый пароль.',
        newPassword: 'Новый пароль.',
        confirmPassword: 'Подтвердите код.',
        confirm: 'подтверд',
        isEmpty: 'Заполните.',
        same: 'Подтверждение того, что код соответствует новому',
        noCorrect: 'Номи корванд ё парол нодуруст аст',
        pwdSix: 'Пароль должен быть 6 и более.'
    },
    lgout: {
        message: 'Нужно будет войти снова после списания, подтвердить это или нет'
    },
    customer: {
        title: 'Эксклюзивное обслуживание клиентов',
        name: 'Обслуживание клиентов',
        action: 'Немедленное консультирование'
    }
}
