// import request from './request.js';
import fetch from '../utils/fetch.js'

// 注册
export function register(query) {
  return fetch({
    url: '/api/register',
    method: 'post',
    data: query
  })
}

// 登录
export function login(query) {
  return fetch({
    url: '/api/login',
    method: 'post',
    data: query
  })
}

// 抢单管理
export function grabList(query) {
  return fetch({
    url: '/api/grab/list',
    method: 'post',
    data: query
  })
}
// 抢单开关
export function grabSwitch(query) {
  return fetch({
    url: '/api/grab/grabSwitch',
    method: 'post',
    data: query
  })
}

// 派单
export function dispatchOrder(query) {
  return fetch({
    url: '/api/dispatch/order',
    method: 'post',
    data: query
  })
}
// 查询已派单
export function dispatchList(query) {
  return fetch({
    url: '/api/dispatch/list',
    method: 'post',
    data: query
  })
}
// 充值管理
export function topupList(query) {
  return fetch({
    url: '/api/topup/list',
    method: 'post',
    data: query
  })
}
// 充值审核

export function topupVerifyt(query) {
  return fetch({
    url: '/api/topup/verify',
    method: 'post',
    data: query
  })
}
// 提现列表
export function withdrawalList(query) {
  return fetch({
    url: '/api/withdrawal/list',
    method: 'post',
    data: query
  })
}
// 提现审核
export function withdrawalverify(query) {
  return fetch({
    url: '/api/withdrawal/verify',
    method: 'post',
    data: query
  })
}

// 自动匹配
export function autoGrabOrder(query) {
  return fetch({
    url: '/api/grab/autoGrabOrder',
    method: 'post',
    data: query
  })
}

// 余额
export function balance(query) {
  return fetch({
    url: '/api/member/balance',
    method: 'post',
    data: query
  })
}
// 订单确认
export function grabConfirm(query) {
  return fetch({
    url: '/api/grab/grabConfirm',
    method: 'post',
    data: query
  })
}

// 获取已完成未完成订单
export function getList(query) {
  return fetch({
    url: '/api/taskOrder/getList',
    method: 'post',
    data: query
  })
}

// 修改密码
export function revisePwd(query) {
  return fetch({
    url: '/api/member/revisePwd',
    method: 'post',
    data: query
  })
}
// 修改提现密码
export function reviseWdPwd(query) {
  return fetch({
    url: '/api/member/reviseWdPwd',
    method: 'post',
    data: query
  })
}
// 账变记录
export function balanceRecord(query) {
  return fetch({
    url: '/api/member/balanceRecord',
    method: 'post',
    data: query
  })
}
// 充值记录
export function topupRecord(query) {
  return fetch({
    url: '/api/member/topupRecord',
    method: 'post',
    data: query
  })
}
// 提款记录
export function withdrawRecord(query) {
  return fetch({
    url: '/api/member/withdrawRecord',
    method: 'post',
    data: query
  })
}
export function withdrawBalance(query) {
  return fetch({
    url: '/api/member/withdrawBalance',
    method: 'post',
    data: query
  })
}

export function editUsdt(query) {
  return fetch({
    url: '/api/member/editUsdt',
    method: 'post',
    data: query
  })
}
export function getReport(query) {
  return fetch({
    url: '/api/member/teamReport',
    method: 'post',
    data: query
  })
}
export function address(query) {
  return fetch({
    url: '/api/pay/address',
    method: 'get',
    data: query
  })
}
// 获取客服地址
export function getCustomerAddress(query) {
  return fetch({
    url: '/api/customer/get',
    method: 'get',
    data: query
  })
}
export function topupAdd(query) {
  return fetch({
    url: '/api/topup/add',
    method: 'post',
    data: query
  })
}
