<template>

 <div data-v-d7859222="" data-v-4323ade8="" class="self home pore">
                    <div data-v-d7859222="" class="van-nav-bar van-hairline--bottom">
                        <div class="van-nav-bar__content">
                            <!--左侧返回按钮-->
                            <!--<div class="van-nav-bar__left van-haptics-feedback"><i-->
                            <!--        class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow">&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</i>&lt;!&ndash;&ndash;&gt;-->
                            <!--</div>-->
                            <div class="van-nav-bar__title van-ellipsis">{{$t('teamReport.teamReport')}}</div><!---->
                        </div>
                    </div>
                    <random></random>
                    <div data-v-d7859222="" class="list">
                        <random></random>
                        <div data-v-d7859222="" class="container">
                            <div data-v-d7859222="" class="orders">
                                <random></random>
                                <div data-v-d7859222="" class="funcs">
                                    <div data-v-d7859222="" class="item"><span data-v-d7859222=""
                                            class="l">{{$t('teamReport.all')}}</span><span data-v-d7859222="" class="r">{{reportObj.total}}</span>
                                    </div>
                                    <div data-v-d7859222="" class="item"><span data-v-d7859222=""
                                            class="l">{{$t('teamReport.number')}}</span><span data-v-d7859222="" class="r">{{reportObj.teamCount}}</span></div>
                                </div>
                                <random></random>
                                <div data-v-d7859222="" class="funcs">
                                    <div data-v-d7859222="" class="item"><span data-v-d7859222=""
                                            class="l">{{$t('teamReport.commissionTeam')}}</span><span data-v-d7859222="" class="r">{{reportObj.teamCommission}}</span></div>
                                </div>
                            </div>
                            <random></random>
                            <div data-v-d7859222="" class="data">
                                <div data-v-d7859222="" class="table">
                                    <div data-v-d7859222="" class="tr"><span data-v-d7859222=""
                                            style="min-width: 80px;"></span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{$t('teamReport.number')}}</span><span data-v-d7859222=""
                                            style="min-width: 100px;">{{$t('teamReport.commissionTeam')}}</span></div>
                                    <random></random>
                                    <div data-v-d7859222="" class="tr"><span data-v-d7859222=""
                                            style="min-width: 80px;">{{$t('teamReport.one')}}</span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{reportObj.level1Count}}</span><span data-v-d7859222=""
                                            style="min-width: 100px;">{{reportObj.level1Commission}}</span></div>
                                    <div data-v-d7859222="" class="tr"><span data-v-d7859222=""
                                            style="min-width: 80px;">{{$t('teamReport.two')}}</span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{reportObj.level2Count}}</span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{reportObj.level2Commission}}</span></div>
                                    <div data-v-d7859222="" class="tr"><span data-v-d7859222=""
                                            style="min-width: 80px;">{{$t('teamReport.three')}}</span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{reportObj.level3Count}}</span><span data-v-d7859222=""
                                            style="min-width: 130px;">{{reportObj.level3Commission}}</span></div>
                                </div>
                                <random></random>
                            </div>
                        </div>
                        <random></random>
                    </div>
                </div>

</template>

<script>


import "../../assets/files/chunk-vendors.2a887a0e.css";
import "../../assets/files/app.026297a5.css";
import "../../assets/files/717.886187b2.css";
import "../../assets/files/index.830c625c.css";
import { getReport } from '../../api/index';
import random from '../../components/random.vue'

export default {
  name: 'Login',

  data() {
    return {
      reportObj: {}

    }
  },
  watch: {

  },
  mounted() {
this.getReport()
  },
  components: { random },
  methods: {
    getReport() {
      getReport({account: window.localStorage.account}).then(res => {
        if (res.data.status == 0) {
          this.reportObj = res.data.result
        }
      })
    }

  }
}
</script>

<style scoped>
.auto-wrap {
  word-wrap: break-word;      /* 旧版本浏览器支持 */
  overflow-wrap: break-word;  /* 标准属性 */
}
</style>
