const { register } = require("@/api");

//韩语
module.exports = {
    all: {
        success: '작업 성공',
        error: '작업 실패',
        cancel: '취소',
        confirm: '확인',
        tip: '제시',
        lackBalance: '잔액이 부족하다',
        withdrawPwdErr: '현금 인출 암호가 잘못되었습니다',
        notComplete: '필요한 홀수가 완료되지 않았습니다',
        notMessage: '사용자가 출금 정보를 결합하지 않았습니다'
    },
    login:{
        login: '로그인',
        register: '등록',
        placeholder1: '로그인 계좌 번호를 입력해 주세요',
        placeholder2: '로그인 비밀번호를 입력하십시오'
    },
    register: {
        invitationCode: '초대 코드',
        phone: '핸드폰 번호',
        password: '암호',
        confirmPassword: '비밀번호 확인',
        withdrawalPassword: '현금 인출 비밀번호',
        login: '로그인',
        register: '등록'
    },
    // 首页
    index: {
        myService: '나의 서비스',
        download: '다운로드',
        help: '도움',
        partners: '협력 동반자',
        selectLanguage: '언어 선택',
        incomeCommission: '수입수수료'
    },
    help: {
        title: "도움",
        question1: "1. AmazonBOT이란 무엇인가요?",
        question2: "AmazonBOT은 전 세계 아마존 웹 상인들이 더 많은 주문 판매 및 아마존 웹 상점의 탐색 데이터를 늘리기 위해 도움을 주는 마케팅 프로모션 회사입니다. 우리는 아마존과 상인, 소비자 간의 삼방형 이윤 마케팅 모델에 전념하고 있습니다.",
        question3: "최신 P2P 블록체인 기술을 결합하여 사용자와 상인을 빠르게 연결하며, USDT (TRC20, ERC20)를 통한 소비자와 상인 간의 결제를 촉진합니다. 가입한 사용자는 주문 수수료를 받으며, 동시에 상인은 상점 판매 데이터를 증가시킵니다. 인터넷 블록체인 모델에서의 최신 수익 모델입니다!",
        question4: "2. AmazonBOT의 작동 원리는 무엇인가요?",
        question5: "매일 아마존 피드백을 통해 아마존에서 판매를 증가시킬 필요가 있는 상품이 나타납니다. 사용자는 단 한 번의 클릭으로 주문을 쉽게 생성할 수 있으며, 시스템은 주문을 자동으로 생성합니다. 사용자는 블록체인 USDT로 주문 금액을 지불하고 매일 수수료를 받게 됩니다.",
        question6: "3. 왜 동일한 화폐 간에 두 거래 사이에 가격 차이가 있나요?",
        question7: "화폐의 자유로운 이동을 방해하는 모든 요소는 가격 차이를 초래할 수 있습니다. 이러한 요소에는 화폐 이체 속도, 네트워크 상태, 화폐 입장 제한, 지역별로 화폐에 대한 인식 등이 포함됩니다. 따라서 동일한 화폐가 서로 다른 거래에서 가격 차이를 보일 수 있습니다.",
        question8: "4. 투자 수익?",
        question9: "상품 가격이 높을수록 주문 수수료가 높아집니다. 동시에 AmazonBOT은 무작위로 대량 수수료 주문을 배포합니다.",
        trad: "거래 규칙",
        trad1: "시스템은 매일 사용자에게 60건의 상품 주문을 자동 생성하며, 사용자는 각 주문 결제마다 0.5%의 수수료를 얻습니다. 시스템은 무작위로 대량 수수료 주문을 배포합니다.",
        trad2: "60건의 주문을 완료한 후 USDT를 인출할 수 있습니다. (60건의 주문을 완료하지 않으면 시스템은 당일 남은 주문 수량까지 자동 중지됩니다.)",
        top_up: "어떻게 충전하나요?",
        top_up1: "충전 전에 출금 주소를 먼저 바인딩하십시오 (TRC-20 지원)USDT 주소, 앱이나 웹에서 충전을 클릭한 후 사용 중인 블록체인 (TRC-20)을 선택하여 충전할 수 있습니다.",
        withdraw: "어떻게 인출하나요?",
        withdraw1: "충전 전에 출금 주소를 먼저 바인딩하십시오 (TRC-20 지원)USDT 주소, 앱이나 웹에서 충전을 클릭한 후 사용 중인 블록체인 (TRC-20)을 선택하여 충전할 수 있습니다.",
        invite: "친구를 초대하는 방법",
        invite1: "당신은 그들을 초대하기 위해 초대 링크 또는 초대 코드를 사용할 수 있으며, 그들은 성공적으로 등록한 후 당신의 팀 멤버가 됩니다.",
        invite2: "친구를 초대할 때 낮은 레벨의 친구가 완료한 주문 수익의 30%를 받게됩니다. Lv1-59%, Lv2-19%, Lv3-15%.",
    },
    // 订单列表
    order: {
        toSubmit: '전송되지 않음',
        submitted: '제출됨',
        totalOrder: '주문 총액',
        commission: '커미션',
        timeLimit: '임무 시한',
        orderDetail: '주문서 상세정보',
        orderNumber: '주문서 번호',
        orderTime: '주문 발주 시간',
        commodityPrice: '상품 단가',
        quantityItems: '상품 수량',
        close: '닫기',
        confirm: '확인',
        submitOrder: '주문서를 제출하다',
        noOrder: '주문 없음'
    },
    // 抢单
    stealOrder: {
        myBalance: '내 잔고',
        completions: '완성한 수',
        numberJobs: '작업 갯수',
        autoMatch: '자동 일치',
        todayEarning: '금일 수익',
        todayOrder: '금일주문서',
        confirm: '확인',
        choose: '사업자 선택 중',
        match: '항목 일치 중',
        message1: '잔액이 부족하다',
        message2: '아직 주문서가 제출되지 않았습니다',
        message3: '증권 구매 금지.',
        message4: '신규 주문없음!'
    },
    // 团队报告
    teamReport: {
        teamReport: '팀 리포트',
        all: '모든 데이터는',
        number: '팀 수',
        commissionTeam: '단체주문수수료',
        one: '1 급',
        two: '2 급',
        three: '3 급'
    },
    // 我的
    my: {
        code: '보급 코드',
        item1: '충전',
        item2: '인출',
        item3: '장부변동기록',
        item4: '현금 인출 기록',
        item5: '예금 인출 정보',
        item6: '충전 기록',
        item7: '정보 공고',
        item8: '암호',
        item9: '출구'
    },
    topup: {
        copy: '복사',
        record: '기록',
        paytype: '지불 방식',
        amountLimit: '금액 제한',
        upload: '충전 증명서를 제출합니다',
        submit: '제출',
        title: '충전 캡처 인증서를 업로드하시고 충전 금액을 입력해주세요',
        money: '금액을 입력하십시오',
        exceed: '그림의 크기가 1m를 넘으면 안 됩니다',
        tip: 'jpg/png 파일만 업로드할 수 있으며 1M을 초과하지 않습니다.'
    },
    withdrawal: {
        withdrawals: '인출',
        myBalance: '내 잔고',
        usdt: 'usdt 현금 인출',
        withdrawalBalance: '현금 인출 금액',
        determine: '확정현금인출'
    },
    system: {
        systemNotify: '시스템 알림',
        mail: '사이트 메시지',
        noData: '불충분한 증거'
    },
    withdrawalInfo: {
        withdrawalsInfo: '예금 인출 정보',
        usdtAddress: 'usdt 주소',
        modify: '수정'
    },
    withdrawalRecord: {
        withdrawalsRecord: '현금 인출 기록',
        applicationAmount: '청구 금액',
        serviceCharge: '수수료',
        receipt: '입금',
        turnDown: '현금 인출 기각: 인출 정보가 잘못되었습니다. 궁금한 점이 있으면 고객센터에 연락하십시오.'
    },
    accountRecord: {
        accountRecordTitle: '장부변동기록',
        changeType: '거래 유형',
        trading: '거래',
        previousAmount: '이전 금액',
        changeAmount: '장부변액',
        changeTime: '계정 변경 시간',
        commission: '반 노동자'
    },
    topupRecord: {
        title:  '충전 기록',
        success: '충전이 성공하다'
    },
    password: {
        title: '암호',
        loginPassword: '로그인 비밀번호',
        cashPassword: '현금 인출 비밀번호',
        oldPassword: '이전 비밀번호',
        newPassword: '새 비밀번호',
        confirmPassword: '비밀번호 확인',
        confirm: '확인',
        isEmpty: '완전하게 작성하십시오',
        same: '비밀번호가 새 비밀번호와 일치하는지 확인합니다',
        noCorrect: '사용자 이름 또는 암호 오류',
        pwdSix: '비밀번호는 6자리 이상 필요'
    },
    lgout: {
        message: '로그아웃 후, 확인 여부를 다시 로그인해야 합니다'
    },
    customer: {
        title: '전용 고객 서비스',
        name: '고객 서비스',
        action: '지금 문의'
    }
}
