import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
// 用Vuex.Store对象用来记录token
const store = new Vuex.Store({

  state: {
    // 存储token
    token:"",
    account: '',
    inviteCode: '',
    topUpUsdt: '',
    customer: ''
  },
  getters: {
	getToken: state => {
        return state.token
    },
    getTopUpUsdt: state => {
      return state.topUpUsdt
    },
    getCustomer: state => {
      return state.customer
    },
  getUserInfo: state => {
        return state.userInfo
  }
  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state,token) {
      state.token = token;
      console.log('token000000000', token)
      localStorage.setItem('token', token);
      console.log('store、localstorage保存token成功！');
    },
    setAccount(state, account) {
      state.account = account
      localStorage.setItem('account', account);

    },
    setInviteCode(state, inviteCode) {
      state.inviteCode = inviteCode
      localStorage.setItem('inviteCode', inviteCode);
    },
    setTopUpUsdt(state, topUpUsdt) {
      state.topUpUsdt = topUpUsdt
      localStorage.setItem('topUpUsdt', topUpUsdt);
    },
    setCustomer(state, customer) {
      state.customer = customer
      localStorage.setItem('customer', customer);
    },
    delToken(state) {
      state.token = "";
      localStorage.removeItem("token");
    }
  }
});

export default store;
