<template>
  <!--随机start-->
  <div style="display: none">
    <div>{{ randomBase64 }}</div>
  </div>
  <!--随机end-->
</template>
<script>
import {generateRandomBase64} from '../utils/utils'
export default {
    data() {
        return {
            randomBase64: ''
        }
    },
    created() {
        this.randomBase64 = generateRandomBase64()
    }
}
</script>