import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import store from './store'
import VueI18n from 'vue-i18n'
import zh from './language/lang0'
import en from './language/lang1'
// element-ui所提供的css样式
import 'element-ui/lib/theme-chalk/index.css'
// import { Message } from 'element-ui';

Vue.use(Element)
Vue.use(VueI18n)

// //定义一个新的Message方法，多传入一个offset参数
// const $message = options => {
//   return Message({
//     ...options,
//     offset: 60
//   });
// }
// //重写方法,将offset写入options
// ['success', 'warning', 'info', 'error'].forEach(type => {
//   $message[type] = options => {
//     if (typeof options === 'string') {
//       options = {
//         message: options
//       };
//     }
//     options.type = type;
//     return Message(options);
//   };
// });
// //将$message挂载到this上
// Vue.prototype.$message = $message;
// //不加这行代码运行this.$message.closeAll时会报错
// Vue.prototype.$message.closeAll = Message.closeAll






Vue.config.productionTip = false

const i18n=new VueI18n({
  locale:'lang1',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages:{
    'lang0':require('./language/lang0'),
    'lang1':require('./language/lang1'),
    'lang2':require('./language/lang2'),
    'lang3':require('./language/lang3'),
    'lang4':require('./language/lang4'),
    'lang5':require('./language/lang5'),
    'lang6':require('./language/lang6'),
    'lang7':require('./language/lang7'),
    'lang8':require('./language/lang8'),
    'lang9':require('./language/lang9'),
    'lang10':require('./language/lang10')
  }
})



// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to,from,next) => {
//   console.log('to', to)
//   if(to.path === '/'){
//     next();
//   }else{
//     let token = window.localStorage.token;
//     console.log('main.js token', token)
//     if(token === 'null' || token === '' || token === undefined){
//       next('/')
//     }else{
//       next();
//     }
//   }
// })


new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
